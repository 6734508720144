/* global charlie */

class CharlieTalks {
  setup() {
    this.createSingularity();
    this.bindEvents();
  }

  /**
   * Binds all the events required for Charlie Talks. The individual binding
   * functions check if the element exists, the event hasn't already been
   * bound and anything else they need to so this function can be called
   * repeatedly if needs be.
   */
  bindEvents() {
    this.bindCloseButton();
  }

  /**
   * Makes Charlie Talks "speak". Used for displaying messages to the user.
   *
   * @param {Object} messageProps An object containing the properties with which
   *                              the notification will be built:
   *                              status (required), message (required),
   *                              icon_path (required), color_class (optional).
   * @param {Boolean} skipTimeout When true, skips the delay on showing the
   *                              message. Useful in our automated tests.
   */
  speak(messageProps, skipTimeout = false) {
    const defaults = { color_class: 'u-bg' };
    const properties = { ...defaults, ...messageProps };
    const typingDelay = skipTimeout ? 0 : 250;
    const messageDelay = skipTimeout ? 0 : 750;

    this.reset(); // Avoid conflicting with other messages

    setTimeout(() => {
      this.reset(); // Avoid conflicting with other messages
      this.startTyping();

      setTimeout(() => {
        this.reset();
        this.AIElement()
          .append(this.buildMessage(properties))
          .addClass('is-notifying');

        this.bindEvents();
        setTimeout(() => { this.hideMessage(); }, 7000);
      }, messageDelay);
    }, typingDelay);
  }

  /**
   * Hides any displayed messages.
   */
  hideMessage() {
    this.AIElement()
      .removeClass('is-notifying')
      .children('.ai-charlie--notifies').remove();
  }

  /**
   * Show the Charlie Talks is typing animation.
   */
  startTyping() {
    this.AIElement()
      .append(`<div class="ai-charlie--types">
        <div class="pulsing-circle js-pulsing"></div>
        <div class="pulsing-circle js-pulsing"></div>
        <div class="pulsing-circle js-pulsing"></div>
      </div>`)
      .addClass('is-typing');
    this.animateTyping();
  }

  /**
   * Hide any messages, typing or menu that is currently open
   */
  reset() {
    this.stopTyping();
    this.hideMessage();
    this.menuElement().removeClass('is-active');
  }

  /**
   * Hide the Charlie Talks is typing animation.
   */
  stopTyping() {
    this.AIElement()
      .removeClass('is-typing')
      .children('.ai-charlie--types').remove();
  }

  /**
   * Animate the typing icon before a message is shown.
   */
  animateTyping() {
    let incrementTime = 0;

    $('.js-pulsing').each((_, element) => {
      const $element = $(element);
      setTimeout(() => $element.addClass('is-animating'), incrementTime);
      incrementTime += 200;
    });
  }

  /**
   * Builds the HTML required for displaying a message.
   *
   * @param {Object} properties See `messageProps` on the `speak` method.
   *
   * @return {String} The HTML used to display an alert.
   */
  buildMessage(properties) {
    return `<div class="ai-charlie--notifies alert-dismissible">
      <button class="ai-charlie__close js-charlie-close" data-dismiss="alert" aria-label="Close">
        <span class="ai-charlie__close-text">Close</span>
        <svg class="o-icon">
          <use xlink:href="${properties.icon_path}#app__close"></use>
        </svg>
      </button>
      <div class="ai-charlie__status ${this.statusCSSClass(properties)}">
        <div class="ai-charlie__status-icon js-charlie-icon">
          <svg class="o-icon text--white">
            <use xlink:href="${properties.icon_path}#notification__${properties.status}"></use>
          </svg>
        </div>
      </div>
      <div class="ai-charlie__message">
        <p class="u-margin--0 u-color--charcoal">${properties.message}</p>
      </div>
    </div>`;
  }

  /**
   * Converts a message's status into the appropriate background CSS class.
   *
   * @params {Object} properties
   *
   * @return {String}
   */
  statusCSSClass(properties) {
    switch (properties.status) {
      case 'notice':
        return `${properties.color_class}--primary`;
      case 'error':
      case 'alert':
        return `${properties.color_class}--danger`;
      case 'success':
        return `${properties.color_class}--success`;
      default:
        return '';
    }
  }

  /**
   * Adds the Charlie AI element to the DOM.
   */
  createSingularity() {
    this.logoElement().html('<aside class="ai-charlie js-charlie-ai"></aside>');
  }

  /**
   * Handle clicking the "Close" button on a message.
   */
  bindCloseButton() {
    if (this.CloseElement().length !== 1) return;
    if (this.CloseElement().data('initialized')) return;

    this.CloseElement().click((e) => {
      e.preventDefault();
      this.hideMessage();
    }).data('initialized', true);
  }

  /**
   * Shortcut functions for accessing elements in the DOM.
   */
  AIElement() { return $('.js-charlie-ai'); }

  CloseElement() { return $('.js-charlie-close'); }

  logoElement() { return $('.js-charlie'); }

  menuElement() { return $('.js-charlie-links'); }
}

if (typeof charlie !== 'undefined') {
  charlie.onReady(() => {
    charlie.talks = new CharlieTalks();
    charlie.talks.setup();
  });
}

export default CharlieTalks;
