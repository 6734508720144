import { Controller } from '@hotwired/stimulus'

/*
*  Handles the show/hide of the bottom right Charlie AI Logo menu
*  Keeps logic separate from what Charlie Talks flash notification behaviour
*/
export default class extends Controller {
  static targets = ['footerLogo', 'ai', 'links'];
  
  connect() {
    this.createSingularity();
    this.bindFooterElementClick();
  }

  bindFooterElementClick() {
    const charlieAI = this.aiTarget
    const footerLogo = this.footerLogoTarget;
    const menu = this.linksTarget;
    
    footerLogo.addEventListener('click', (e) => {
      e.preventDefault();
      
      if (charlieAI.classList.contains('is-notifying')) {
        this.hideMessage();
      } else {
        menu.classList.toggle('is-active');
      }
    });
  }

  hideMessage() {
    const charlieAI = document.getElementById('ai-target')
    charlieAI.classList.remove('is-notifying')
    const child = charlieAI.querySelector('.ai-charlie--notifies');
    child.remove();
  }

  createSingularity() {
    const charlieAI = this.aiTarget
    charlieAI.innerHTML = '<aside class="ai-charlie js-charlie-ai" id="ai-target"></aside>';
  }
}