import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="load-more"
export default class extends Controller {
  static values = { frameIdentifier: String }

  reloadTurboFrame(event) {
    event.preventDefault()
    const frame = document.getElementById(this.frameIdentifierValue);
    frame.src = event.target.href
    frame.reload()
  }
}
